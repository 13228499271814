<script setup lang="ts">
import { storeToRefs } from 'pinia'
import ButtonGroup from '~/components/UI/ButtonGroup.vue'
import CitySelectField from '~/components/UI/CitySelectField.vue'
import SelectField from '~/components/UI/SelectField.vue'
import type { TelcoRateType } from '@/types'
import { ActivationCost, Client, Modem, TelcoSubCategory } from '@/types'
import Button from '~/components/UI/Button.vue'

defineProps<{
  options: TelcoRateType[],
}>()

const emit = defineEmits(['update', 'submit'])

const { supplierList } = storeToRefs(useBrandStore())

const {
  city,
  clientType,
  telcoSubCategory,
  supplier,
  activationCost,
  modem,
  voice
} = storeToRefs(useFilterStore())
const {
  setTelcoSubCategory,
  setClientType,
  setCity,
  setProvince,
  setSupplier,
  setActivationCost,
  setModem,
  resetFilters,
} = useFilterStore()

const onCity = (value: any) => {
  setCity(value.nome)
  setProvince(value.provincia.nome)
}
</script>

<template>
  <form class="isolate flex flex-1 flex-col gap-4">
    <ButtonGroup id="category" :value="telcoSubCategory" :options="[
      { id: 1, label: 'Tutte', value: TelcoSubCategory.ALL },
      { id: 2, label: 'Internet', value: TelcoSubCategory.WIFI },
      { id: 3, label: 'Chiamate', value: TelcoSubCategory.PHONE },
    ]" @input="setTelcoSubCategory">
      <template #label> Tipo di linea </template>
    </ButtonGroup>

    <SelectField id="supplier" :value="supplier" :options="supplierList" @input="setSupplier">

      <template #label> Fornitore </template>
    </SelectField>

    <ButtonGroup id="clientType" :value="clientType" :options="[
      { id: 1, label: 'Tutti', value: Client.ALL },
      { id: 2, label: 'Privato', value: Client.PRIVATE },
      { id: 3, label: 'Business', value: Client.BUSINESS },
    ]" @input="setClientType">

      <template #label>
        <div class="flex items-center gap-2">
          <span>Tipo cliente</span>
        </div>
      </template>
    </ButtonGroup>

    <CitySelectField id="city" :value="city" :query="unref(city)" @input="onCity">

      <template #label>
        Verifica copertura
      </template>
    </CitySelectField>
    <hr>

    <ButtonGroup id="activationCost" :value="activationCost" :options="[
      { id: 1, label: 'Tutte', value: ActivationCost.ALL },
      { id: 2, label: 'Incluso', value: ActivationCost.INCLUDED },
      { id: 3, label: 'Non incluso', value: ActivationCost.NOT_INCLUDED },
    ]" @input="setActivationCost">

      <template #label> Costo di attivazione </template>
    </ButtonGroup>

    <ButtonGroup id="modem" :value="modem" :options="[
      { id: 1, label: 'Tutte', value: Modem.ALL },
      { id: 2, label: 'Incluso', value: Modem.INCLUDED },
      { id: 3, label: 'Non incluso', value: Modem.NOT_INCLUDED },
    ]" @input="setModem">

      <template #label> Modem </template>
    </ButtonGroup>

    <div class="sm:hidden flex flex-col my-4">
      <Button class="h-10" @click="$emit('submit')">
        Applica
      </Button>
    </div>
    <div class="flex flex-col my-4">
      <Button class="h-10" @click="resetFilters">
        Reimposta filtri
      </Button>
    </div>
  </form>
</template>
